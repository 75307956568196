import React from 'react';

function RodoHeader (props) {


    let { types } = props
    return (
        <header>
            {types === "main" ? <h2>Szanowna Użytkowniczko, Szanowny Użytkowniku,</h2> : null}
            {types === "advenced" ? <h2>Ustawienia zaawansowane</h2> : null}
            {types === "parners" ? <h2>Lista Zaufanych partnerów</h2> : null}
            <hr />
        </header>
    )

}

export default RodoHeader