import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import RodoWrapper from './components/rodoWrapper/RodoWrapper'
import Cookies from 'js-cookie';



class App extends Component {

  state = {
    isChecked: true,
    gdprCookie: Cookies.get('gdprContest'),
    show: true,
  }

  inputHandleChange = () => {

    this.setState({
      isChecked: !this.state.isChecked
    })
  }

  setCookie = (isChecked) => {

    if (isChecked === true) {
      this.setState({
        gdprCookie: Cookies.set('gdprContest', '1', { expires: 365 }),
        show: false
      })
    } else {
      this.setState({
        gdprCookie: Cookies.set('gdprContest', '0', { expires: 1 }),
        show: false
      })
    }
  }



  render() {

    console.log(this.state.gdprCookie)

    return (
      <>
        {this.state.gdprCookie !== undefined ? null :

          (this.state.show === true ?

            <RodoWrapper
              onChange={this.inputHandleChange}
              isChecked={this.state.isChecked}
              setCookie={this.setCookie}
            /> : null)
        }
      </>
    );

  }
}

export default App;
