import React, { Component } from 'react';
import styles from '../rodoWrapper.module.scss'


const types = {
    cookies: "cookies",
    personaldata: "personaldata",
    partners: "parners"
}


function Cookies() {
    return (
        <div className={styles.advanceSettings}>
            <h3 >Instalowanie plików cookies itp. na Twoich urządzeniach i dostęp do tych plików.</h3>
            <div className={styles.content}>
                <p >Na potrzeby niniejszej polityki prywatności, przyjmuje się następujące znaczenie poniższych pojęć:</p>
                <ul >
                    <li >Administrator – SEOJOKER – Mateusz Mikoś, FRANCISZKA BIELOWICZA 65 32-040 ŚWIĄTNIKI GÓRNE, NIP 6811966900,</li>
                    <li >Strona – strona internetowa dostępna pod adresem http://calculators.pl/</li>
                    <li >Użytkownik – każdy podmiot, który korzysta ze Strony.</li>
                </ul>
                <ol >
                    <li >Administrator wykorzystuje pliki cookies (ciasteczka), czyli niewielkie informacje tekstowe, przechowywane na urządzeniu końcowym Użytkownika (np. komputerze, tablecie, smartfonie). Cookies mogą być odczytywane przez system teleinformatyczny Administratora.</li>
                    <li >Administrator przechowuje pliki cookies na urządzeniu końcowym Użytkownika, a następnie uzyskuje dostęp do informacji w nich zawartych w celach statystycznych, w celach marketingowych (remarketing) oraz w celu zapewnienia prawidłowego działania Strony.</li>
                    <li >Administrator wskazuje niniejszym, że pliki cookies mogą być przez Użytkownika usunięte po ich zapisaniu przez Administratora, poprzez odpowiednie funkcje przeglądarki internetowej, programy służące w tym celu lub skorzystanie z odpowiednich narzędzi dostępnych w ramach systemu operacyjnego, z którego korzysta Użytkownik.</li>
                    <li >Informacja jak wyłączyć obsługę ciastek znajduje się <a href="/ustawienia-przegladarek">pod tym adresem</a></li>
                </ol>
                <p >Administrator informuje niniejszym Użytkownika, że stosuje następujące technologie śledzące działania podejmowane przez Użytkownika w ramach Strony:</p>
                <p >Google Analytics - narzędzie do zbierania statystk przez odwiedzjących stronę</p>
                <p >Zgadzając się na naszą politykę zagadzasz się na zainstalowanie na twoim komputerze ciasteczka zgody o nazwie: gdprCotenst</p>
            </div>
        </div>
    )
}

function PersonalData(props) {

    const { isChecked, onChange, optionHandler } = props

    return (

        <div className={styles.advanceSettings}>
            <h3>Przetwarzanie danych osobowych w celach marketingowych przez naszych Zaufanych Partnerów.</h3>
            <div className={styles.userDataConsetst}>
                <label className={styles.inputContainer}>
                    <input type="checkbox"
                        checked={isChecked}
                        onChange={onChange}
                    />
                    <span className={styles.checkMark}></span>
                </label>
                Aktywna
            </div>

            <section>
                <p >Zgoda dotyczy przetwarzania Twoich danych osobowych, które udostępniasz w historii przeglądania stron i aplikacji internetowych, w celach marketingowych (obejmujących zautomatyzowaną analizę Twojej aktywności na stronach internetowych i w aplikacjach w celu ustalenia Twoich potencjalnych zainteresowań dla dostosowania reklamy i oferty przez np. zestawianie w odpowiednich grupach użytkowników, oraz związane z tym niezbędne działania statystyczne) przez naszych Zaufanych Partnerów. Na podstawie tej zgody, Twoje dane, w tym zebrane informacje o Twojej aktywności na stronach internetowych, będą wykorzystywane do prezentowania Ci reklam produktów i usług naszych Partnerów biznesowych, dopasowanych do Twoich zainteresowań.</p>
                <p className={styles.partners} onClick={()=>optionHandler(types.partners)} ><strong >Pokaż listę zauwanych partnerów</strong></p>
            </section>
        </div>
    )
}



class RodoAdvencedContent extends Component {

    state = {
        option: types.cookies
    }

    optionAdvencedHandler = (type) => {
        this.setState({
            option: type
        })
    }


    render() {

        return (
            <div className={styles.settings}>
                <div className={styles.settingTabs}>
                    <p className={this.state.option === types.cookies ? styles.tabActive : null} onClick={() => this.optionAdvencedHandler(types.cookies)}>Cookies itp.</p>
                    <p className={this.state.option === types.personaldata ? styles.tabActive : null} onClick={() => this.optionAdvencedHandler(types.personaldata)}> Przetwarzanie danych osobowych</p>
                    <hr />
                </div>
                {this.state.option === types.cookies ? <Cookies /> : null}
                {this.state.option === types.personaldata ?
                    <PersonalData
                        onChange={this.props.onChange}
                        isChecked={this.props.isChecked}
                        optionHandler={this.props.optionHandler} /> : null}
            </div>

        )
    }
}

export default RodoAdvencedContent