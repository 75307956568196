import React from 'react';
import styles from '../rodoWrapper.module.scss'

function MoreButton (props) {

        return (
        <>
            <button className={styles.btnElse} {...props} >Ustawienia zaawansowane</button>
        </>
    )

}

export default MoreButton