import React from 'react';
import styles from '../rodoWrapper.module.scss'


function PartnerList() {
    return (


        <div className={styles.table}>
            <table className={styles.cookiesTable}>
                <tbody ><tr >
                    <th >Partner</th>
                    <th >Ciasteczka</th>
                    <th >Przeznaczenie</th>
                </tr>
                    <tr >
                        <td >Google </td>
                        <td >_ga,_gat,_gid</td>
                        <td >zbieranie statystyk Google Analytics</td>
                    </tr><tr >
                        <td >Google </td>
                        <td >IDE,DSID</td>
                        <td >reklamy w systemie .doubleclick.net</td>
                    </tr>
                </tbody></table>
        </div>
    )
}

export default PartnerList 