import React from 'react';
import styles from '../rodoWrapper.module.scss'

function AgreeButton(props) {

    return (
        <>        
            <button className={styles.gdprAgreement} {...props}>Zapisz i zamknij</button>
        </>
    )

}

export default AgreeButton