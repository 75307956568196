import React from 'react';
import styles from '../rodoWrapper.module.scss'

function BackButton (props) {

    return (
        <>        
            <button className={styles.btnElse} {...props}>Wstecz</button>
        </>
    )

}

export default BackButton