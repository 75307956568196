import React from 'react';
import styles from '../rodoWrapper.module.scss'

function RodoMainContent() {
    return (

        <div className={styles.content}>
            <p>Zapoznaj się z naszą Polityką plików cookies, w której informujemy o celu używanych przez nas cookies, ich rodzajach, sposobach korzystania i ich usuwania oraz naszych zaufanych partnerach. Jeśli klikniesz Ok rozumiem, onzacza to że zgadzasz się i akceptujesz naszą <a href="/polityka-prywatnosci">POLITYKĘ PRYWATNOŚCI</a></p>
            <p>Niniejsza polityka prywatności i plików cookies opisuje zasady postępowania z danymi osobowymi oraz wykorzystywania plików cookies i innych technologii w ramach strony internetowej http://calculators.pl/.</p>
            <ol >
                <li>Administratorem strony jest SEOJOKER – Mateusz Mikoś, FRANCISZKA BIELOWICZA 65 32-040 ŚWIĄTNIKI GÓRNE, NIP 6811966900
          </li>
                <li>Kontakt z administratorem możliwy pod adresem e-mail biuro@seojoker.pl</li>
            </ol>
        </div>
    )
}

export default RodoMainContent