import React, { Component } from 'react';
import styles from './rodoWrapper.module.scss'
import RodoHeader from './rodoHeader/RodoHeader'
import RodoMainContent from './rodoContent/RodoMainContent'
import AgreeButton from './buttons/AgreeButton'
import MoreButton from './buttons/MoreButton'
import RodoAdvencedContent from './rodoContent/RodoAdvencedContent'
import BackButton from './buttons/BackButton'
import PartnerList from './rodoContent/PartnerList'

const types = {
    main: "main",
    advenced: "advenced",
    partners: "parners",
}

class RodoWrapper extends Component {

    state = {
        option: types.main,
    }
    
    optionHandler = (type) => {
        this.setState({
            option: type
        })
    }


    render() {

        return (

            <div className={styles.overlay}>
                <div className={styles.gdprWrapper}>
                    <RodoHeader types={this.state.option} />

                    {this.state.option === types.main ? <RodoMainContent /> : null}
                    {this.state.option === types.advenced ?
                        <RodoAdvencedContent
                            onChange={this.props.onChange}
                            isChecked={this.props.isChecked}
                            types={this.state.option}
                            optionHandler={this.optionHandler}
                        />
                        : null}
                    {this.state.option === types.partners ? <PartnerList /> : null}

                    <div className={styles.contests}>
                        <AgreeButton onClick={() => this.props.setCookie(this.props.isChecked)} />
                        {this.state.option === types.main ? <MoreButton onClick={() => this.optionHandler(types.advenced)} /> : null}

                        {this.state.option === types.advenced ? <BackButton onClick={() => this.optionHandler(types.main)} />
                            : (this.state.option === types.partners ?
                                <BackButton onClick={() => this.optionHandler(types.advenced)} />
                                : null

                            )
                        }
                    </div>
                </div>
            </div>
        )

    }

}

export default RodoWrapper